.about {
	#current-title {
		font-size: 20px;
		padding: 8px 16px;
		border: 2px solid;
		border-radius: 28px;
		margin-bottom: 20px;
		color: #154C8A;
	}

	#bio {
		text-align: center;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: 2px;

		strong {
			margin-left: 4px;
		}
	}

	.resume-btn {
		display: inline-block;
    position: relative;
    font-family: inherit;
    line-height: normal;
    vertical-align: top;
    white-space: nowrap;
    margin: 0px;
    padding: 8px;
		text-transform: uppercase;
		color:rgba(0, 0, 0, 0.9);
		letter-spacing: 2px;
    overflow: hidden;
    outline: none;
    background: transparent;
    border-width: 0px;
    border-radius: 4px;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    text-decoration: none;
		text-align: center;

		// button raised effect
		box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;


		background-color: #18ffff;
	}
}